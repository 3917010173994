* {
  font-family: Montserrat, sans-serif;
}
body {
  background-image: url("../public/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0;
  width: 100%;
  height: 100%;
}
.video-js .vjs-overlay-background {
  width: 13% !important;
}
@media (max-width: 500px) {
  .margin-video {
    margin-bottom: 80px;
  }
}
@media (max-width: 700px) {
  .margin-video {
    margin-bottom: 200px;
  }
}
@media (min-width: 700px) {
  .margin-video {
    margin-bottom: 385px;
  }
}
